/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useState } from 'react'
import { useScreenSize } from 'hooks/useScreenSize'
import { getGoogleTag } from './utils'

const DEFAULT_TARGETING = {
    component: 'null',
}

export const AdBanner = ({ slotId, id, targeting = DEFAULT_TARGETING, onLoaded = () => {}, size }) => {
    const bannerRef = useRef(null)
    const [isLoaded, setIsLoaded] = useState(false)
    const { desktop } = useScreenSize()
    const googleTag = getGoogleTag()

    const initializeBanner = () => {
        if (!isLoaded && bannerRef?.current) {
            const bannerRefId = bannerRef?.current?.id
            googleTag?.cmd?.push(() => {
                const adMapping = googleTag
                    ?.sizeMapping()
                    ?.addSize([1024, 768], [[728, 90]])
                    ?.addSize([0, 0], [320, 50])
                const builtMapping = adMapping?.build()
                // NB: ignore the appended in the end hash by using "startsWith"
                const existingSlot = googleTag
                    ?.pubads()
                    ?.getSlots()
                    ?.find(slot => String(slot?.getSlotId()?.getId()).startsWith(slotId))
                if (!existingSlot) {
                    const adRequest = googleTag?.defineSlot(
                        slotId,
                        size || [
                            [728, 90],
                            [320, 50],
                        ],
                        bannerRefId
                    )
                    if (adRequest) {
                        adRequest
                            ?.setTargeting('refresh', 'true')
                            ?.updateTargetingFromMap(targeting)
                            ?.addService(googleTag?.pubads())

                        if (builtMapping) {
                            adRequest?.defineSizeMapping(builtMapping)
                        }
                        googleTag?.enableServices()
                        googleTag?.pubads()?.enableLazyLoad({
                            fetchMarginPercent: 500,
                            renderMarginPercent: 200,
                            mobileScaling: 2.0,
                        })
                        googleTag?.pubads()?.addEventListener('slotRenderEnded', () => {
                            onLoaded()
                            setIsLoaded(true)
                        })
                        googleTag?.display(bannerRefId)
                        googleTag.pubads().refresh()
                    }
                }
            })
        }
    }

    const destroyBanner = () => {
        if (isLoaded && googleTag) {
            const existingSlot = googleTag
                ?.pubads()
                ?.getSlots()
                ?.find(slot => String(slot?.getSlotId()?.getId()).startsWith(slotId))
            googleTag.destroySlots([existingSlot])
            setIsLoaded(false)
        }
    }

    useEffect(() => {
        if (googleTag && !isLoaded) {
            initializeBanner()
        }
    }, [googleTag, isLoaded])

    // Destroy slot when viewport size changes desktop <-> mobile
    useEffect(() => {
        destroyBanner(true)
    }, [desktop, targeting])

    return <div id={id} ref={bannerRef} className='flex justify-center m-0' />
}
