import classNames from 'classnames'
import { Button, Link } from 'components/ui'
import Image from 'next/image'
import { THEME, useTheme } from 'state'

export const ApiBanner = () => {
    const theme = useTheme()

    return (
        <div
            className={classNames(
                'bg-gradient-to-r from-primary via-primary to-primary px-[var(--p-page-sides)] overflow-hidden',
                {
                    'bg-gradient-to-r from-magenta to-primary':
                        theme === THEME.DARK,
                }
            )}
        >
            <div className='container h-full'>
                <div className='flex flex-col items-start justify-between w-full h-full gap-8 pt-10 lg:flex-row lg:gap-7 lg:items-center lg:p-0'>
                    <div className='block self-start order-2 relative -top-2 ml-[clamp(-46px,-7%,-10px)] mb-[-50px] lg:order-1 lg:ml-0'>
                        <Image
                            src={`/static/img/${theme === THEME.DARK ? 'dark' : 'light'}-code.png`}
                            alt='Query'
                            width={640}
                            height={422}
                        />
                    </div>
                    <div className='order-1 text-lightest lg:order-2 lg:w-full'>
                        <h2 className='text-lightest'>Staking Data API</h2>
                        <p className='text-lightest max-w-[300px] mb-3'>
                            Access 1250+ unique data points across 167 assets
                            and 100k+ validators.
                        </p>
                        <div className='grid grid-cols-1 gap-3 sm:grid-cols-2 sm:gap-6'>
                            <div className='font-bold leading-[1.5] whitespace-nowrap'>
                                <p>Up to 3 years</p>
                                <p>Historical Data</p>
                            </div>
                            <div className='font-bold leading-[1.5] whitespace-nowrap'>
                                <p>Trusted by Industry Leaders</p>
                                <div className='flex flex-row flex-wrap items-center gap-5 sm:flex-nowrap'>
                                    <span className='bg-lightest min-w-[80px] h-6'></span>
                                    <span className='bg-lightest min-w-[80px] h-6'></span>
                                    <span className='bg-lightest min-w-[70px] h-6'></span>
                                </div>
                            </div>
                            <Link
                                href='https://api-docs.stakingrewards.com/'
                                blank={false}
                                className='w-full'
                            >
                                <Button
                                    variant='black'
                                    iconClass='icon-arrow-right'
                                    className='w-full'
                                >
                                    API Docs
                                </Button>
                            </Link>
                            <Link
                                href='/staking-data-api'
                                blank={false}
                                className='w-full'
                            >
                                <Button
                                    variant='white'
                                    iconClass='icon-arrow-right'
                                    className='w-full'
                                >
                                    Get Started For Free
                                </Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
