import { SubscribeForm } from 'components/forms/subscribeForm'
import { useJournalPosts } from 'components/journal'
import { ArticlePost } from 'components/journal/articlePost'
import { Skeleton } from 'components/ui'
import { useDeferredRendering } from 'hooks/useDeferredRendering'
import { useRef } from 'react'
import { useMediaPredicate } from 'react-media-hook'
import { MailerLiteGroupId } from 'utils/constants'
import { GTMEvent } from 'utils/GTM'

const POSTS_COUNT = 4

export const NewsletterSection = () => {
    const showVerticalPosts = useMediaPredicate('(max-width: 1199px)')

    const ref = useRef(null)
    const isReady = useDeferredRendering(ref)
    const { data: posts, isLoadingPosts } = useJournalPosts('Staking Insider', 0, POSTS_COUNT, '', isReady)
    const isLoading = !isReady || isLoadingPosts

    return (
        <div ref={ref} className='w-full h-full'>
            <div className='container h-full'>
                <div className='mb-7'>
                    <h2>Staking Insider Newsletter</h2>
                    <p className='text-description'>
                        Join 75k+ subscribers reading the latest staking data &
                        insights weekly.
                    </p>
                </div>
                <div className='grid gap-10 mb-10 sm:grid-cols-1 md:grid-cols-2'>
                    {isLoading
                        ? Array.from(
                              { length: POSTS_COUNT },
                              (_, index) => index
                          ).map(idx => (
                              <div
                                  key={`latest-post-${idx}`}
                                  className='w-full h-[25vw] sm:h-[50vw] md:h-[100px] md:min-w-[480px] md:max-w-[480px]'
                              >
                                  <Skeleton width='100%' height='100%' />
                              </div>
                          ))
                        : posts
                              ?.slice(0, POSTS_COUNT)
                              ?.map((post, idx) => (
                                  <ArticlePost
                                      key={`post-${post?.slug}-${idx}`}
                                      post={post?.attributes}
                                      category=''
                                      isDynamic={showVerticalPosts}
                                      isLoading={isLoading}
                                  />
                              ))}
                </div>
                <SubscribeForm
                    className='flex justify-start'
                    groupId={MailerLiteGroupId.StakingInsider}
                    event={GTMEvent.StakingInsiderSubscribed}
                    eventFrom='inPage'
                />
            </div>
        </div>
    )
}
